
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '@/mixins/StepMixin'
import { MvButton, MvIcon } from '@monosolutions/vue-components'
import HTTPClient from '@/utils/HTTPClient'
import SessionMixin from '@/mixins/SessionMixin'

@Component({
  components: {
    MvIcon, MvButton
  }
})
export default class ChooseTemplate extends Mixins(Step) {
  templates: any = null

  selectedTemplate: number | null = null

  mounted (): void {
    // Will be operational when nginx reverse proxy not capturing browser cookies is solved
    // new SessionMixin().create()

    if (this.$store.state.templatesList.length > 0) {
      this.templates = this.$store.state.templatesList
    } else {
      (async () => {
        this.templates = await this.getTemplatesList()
        this.$store.dispatch('setTemplatesList', this.templates)
      })()
    }

    if (this.$store.state.userData.template.templateId) {
      this.$store.dispatch('setNextStatus', true)
      this.selectedTemplate = this.$store.state.userData.template.templateId
    } else {
      this.$store.dispatch('setCurrentPageInvalid')
    }
  }

  get showSideBar (): boolean {
    return this.$store.state.currentPage.sidebar.show
  }

  isTemplateSelected (templateId: number): boolean {
    return templateId === this.selectedTemplate
  }

  async selectTemplate (templateId: number): Promise<any> {
    this.$store.dispatch('setNextStatus', true)
    this.selectedTemplate = templateId
    await this.$store.dispatch('setUserData', { template: { templateId } })
    this.$store.dispatch('setCurrentPageValid')
  }

  openPreview (templateId: number): void {
    this.$router.push(`/preview-template?id=${templateId}`)
  }

  async getTemplatesList () {
    const halRequest = new HTTPClient('')
    const templatesList: any = []

    try {
      const halTemplatesListResponse: any = await halRequest.get('https://mono.site/api.php/?command=getResellerTemplatesThumbnails')
      const halTemplatesList = halTemplatesListResponse.data[0].resellerTemplates

      for (let i = 0, len = halTemplatesList.length; i !== len; i++) {
        templatesList.push({
          templateId: halTemplatesList[i].id,
          title: halTemplatesList[i].name,
          description: halTemplatesList[i].description,
          thumbnailUrl: `https://rai.monosolutions.com/thumbnails/${halTemplatesList[i].thumbnailURI}`
        })
      }
    } catch (error) {
      console.error('Could not fetch the templates list')
    }

    return templatesList
  }
}
